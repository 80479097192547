import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import SideBar from "../Parts/SideBar2";
import DashboardCard from "./dashboardCard";
import { delTopic, getTopics, toggleActive } from "../../api/topicApi";
import { useSelector } from "react-redux";
import { selectLoggedInUser } from "../../redux/authSlice";
import DashboardLoader from "../loader/DashboardLoader";

const TopicDashboard = () => {
  const [topics, setTopics] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const mainUser = useSelector(selectLoggedInUser);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTopics();
  }, []);

  const fetchTopics = async () => {
    setLoading(true)
    try {
      const res = await getTopics();
      // console.log("res", res);

      setTopics(res);
    } catch (error) {
      console.error("Error fetching topics:", error);
    } finally {
      setLoading(false)
    }
  };

  const handleEdit = (id) => {
    navigate(`/admin/topic/${id}`);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await delTopic(id);
      fetchTopics();
    } catch (error) {
      console.error("Error deleting topic:", error);
    } finally {
      setLoading(false)
    }
  };

  const handleActive = async (id, currentStatus) => {
    setLoading(true);
    try {
      const updated = await toggleActive(id, { isActive: !currentStatus });
      setTopics((prevTopics) =>
        prevTopics.map((topic) =>
          topic._id === id ? { ...topic, isActive: updated.isActive } : topic
        )
      );
    } catch (error) {
      console.error("Error toggling active status:", error);
    } finally {
      setLoading(false)
    }
  };

  const filteredTopics = topics?.filter((topic) =>
    topic?.topicName?.toLowerCase().includes(searchQuery?.toLowerCase())
  ) || [];


  // Safeguard for mainUser and its properties
  const userName = mainUser?.user?.name || "Not found";

  if (loading) return <DashboardLoader />

  return (
    <div className="flex overflow-hidden">
      <SideBar />
      <div className="ml-[15.5vw] p-4">
        <header>
          <h1 className="text-2xl font-bold mb-4">Hi, {userName}</h1>
        </header>
        <div className="flex flex-col items-center">
          <div className="flex w-[1200px] border-2 rounded-t-lg px-4 pt-2 justify-between items-center">
            <div className="flex flex-col">
              <h2 className="text-xl font-semibold mb-1">Topic Dashboard</h2>
              <p className="text-gray-600 mb-4">Keep track of your topics.</p>
            </div>
            <button
              className="h-[39px] mb-4 px-6 py-2 bg-orange-500 text-white rounded"
              onClick={() => navigate("/admin/topic")}
            >
              + Add New Topic
            </button>
          </div>

          <div className="flex w-[1200px] space-x-[700px] border-2 px-2 py-2 -translate-y-[2px] -translate-x-[14.8px] ml-[30px]">
            <div className="flex items-center space-x-2 mb-4">
              <input
                type="text"
                placeholder="Search"
                className="border-2 translate-y-[8px] w-[380px] h-[40px] px-4 py-3 rounded-lg"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button className="translate-y-[8px] h-[40px] px-4 py-2 bg-gray-200 rounded">
                Filters
              </button>
            </div>
          </div>
        </div>

        <div className="w-[1200px] ml-[15.5px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 border-2 rounded-b-lg px-4 py-4 -translate-y-[3px]">
          {filteredTopics?.map((card) => (
            <DashboardCard
              key={card._id}
              name={card.topicName}
              date={card.createdAt.substring(0, 10)}
              isActive={card.isActive}
              toggleActive={() => handleActive(card._id, card.isActive)}
              deletee={() => handleDelete(card._id)}
              edit={() => handleEdit(card._id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopicDashboard;
