import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import logo from "../../assets/SideBar/logo.jpg";
import home from "../../assets/SideBar/home-line.svg";

import layers from "../../assets/SideBar/layers-three-01.svg";

import users from "../../assets/SideBar/users-01.svg";
import lifeBuoy from "../../assets/SideBar/life-buoy-01.svg";
import settings from "../../assets/SideBar/settings-01.svg";
// import avatar from "../../assets/SideBar/Avatar.svg";
import "./sidebar.css";
import { useDispatch } from "react-redux";
import { signOut } from "../../redux/authSlice";
import { UnsavedChangesContext } from "../../context/UnsavedChangesContext";

const sideIcons = [
  ["Configuration"],
  ["Professor"],
  ["Student"],
  ["College"],
  ["University"],
  ["Document Repo", "document"],
  ["Topic"],
  ["Course"],
  ["Semester"],
  ["Degree"]
];

const SideBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hasUnsavedChanges, setHasUnsavedChanges } = useContext(UnsavedChangesContext);

  const handleNavClick = (e, path) => {
    if (hasUnsavedChanges) {
      e.preventDefault();
      const confirm = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (confirm) {
        setHasUnsavedChanges(false); 
        navigate(path);
      }
    }
  };


  const handleLogout = () => {
    dispatch(signOut());
    navigate('/');
  };
  return (
    <div className="fixed z-10 left-0 top-0 overflow-y-auto flex-shrink-0 w-[25vw] lg:w-[15vw] h-screen bg-[#2c1c5f] justify-start items-start inline-flex">
      <div className="w-[250px] self-stretch flex-col justify-between items-start inline-flex">
        <div className="self-stretch h-[360px] pt-8 flex-col justify-start items-start gap-6 flex">
          <div className="self-stretch h-8 pl-6 pr-5 flex-col justify-start items-start flex">
            <div className="shadow justify-start items-center inline-flex">
              <div className="w-8 h-8 rounded-lg justify-center items-center flex">
                <img
                  src={logo}
                  alt="logo"
                  className="w-8 h-8 relative flex-col justify-start items-start flex rounded-lg"
                />
              </div>
              <p className="ml-4 text-white font-bold text-xl">GradBudy</p>
            </div>
          </div>

          <div className="h-[70vh]">
            <div className="self-stretch h-full px-4 flex-col justify-start items-start 3xl:gap-2 flex overflow-x-auto scroll-hide">
              {sideIcons.map((icon, i) => (
                <NavLink key={i}
                  onClick={(e) => handleNavClick(e, `/dashboard/${icon[1] ? icon[1] : icon[0].toLowerCase()}`)}
                  to={`/dashboard/${icon[1] ? icon[1] : icon[0].toLowerCase()}`}
                  className="p-3 w-full rounded-md justify-start items-start gap-2 inline-flex hover:bg-[#7F56D9]"

               >
                  <img src={i > 3 ? layers : i > 1 ? home : lifeBuoy} alt="" className="w-6 h-6 relative" />
                  <p className="ml-4 text-white">{icon[0]}</p>
                </NavLink>
              ))}

              <NavLink
                to="/user_"
                className="p-3 w-full rounded-md justify-start items-start gap-2 inline-flex hover:bg-[#7F56D9] "
              >
                <img src={users} alt="users" className="w-6 h-6 relative" />
                <p className="ml-4 text-white">Users</p>
              </NavLink>

              <NavLink
                to="/user"
                className="p-3 w-full rounded-md justify-start items-start gap-2 inline-flex hover:bg-[#7F56D9] "
              >
                <img src={settings} alt="" className="w-6 h-6 relative" />
                <p className="ml-4 text-white">Settings</p>
              </NavLink>

            </div>
          </div>


        </div>
        <div className="self-stretch h-[80px] px-4 justify-start items-center gap-6 flex">
          {/* <img
            className="w-12 h-12 rounded-[200px]"
            src={avatar}
            alt="avatar"
          /> */}

          <button className="p-3 w-full rounded-md justify-center items-start gap-2 inline-flex bg-[#7F56D9] text-white font-semibold text-center" onClick={handleLogout} >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
