import React, { Suspense, useEffect, useMemo, useState } from "react";
import SideBar from "../Parts/SideBar2";
import ProgressSteps from "./ProgressSteps";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCollegeById } from "../../api/collegeApi";
import { resetCollege, updateSection } from "../../redux/collegeReducer";
import DashboardLoader from "../loader/DashboardLoader";

const Page = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const collegeComp = useMemo(
    () => [
      "",
      "Overview",
      "CampusDetails",
      "CourseDetails",
      "Placements",
      "Gallery",
      "Faq",
    ],
    []
  );
  const [ind, setInd] = useState(1);

  useEffect(() => {
    if (id) fetchColData();
    else dispatch(resetCollege());
  }, [id, dispatch]);

  const fetchColData = async () => {
    setLoading(true);
    try {
      const res = await getCollegeById(id);
      if (res.data) {
        const processed = Object.entries(res.data).reduce(
          (acc, [section, data]) => {
            if (
              section === "_id" ||
              section === "createdAt" ||
              section === "updatedAt"
            )
              acc[section] = data.toString();
            else acc[section] = data;

            return acc;
          },
          {}
        );

        Object.entries(processed).forEach(([section, data]) => {
          dispatch(updateSection({ section, data }));
        });
      }
    } catch (err) {
      console.error("Error getting the college: ", err);
    } finally {
      setLoading(false);
    }
  };

  const curSection = useMemo(() => collegeComp[ind], [ind, collegeComp]);
  const Load = React.lazy(() => import(`./${curSection}`));

  if (loading) return <DashboardLoader />

  return (
    <div className="flex bg-purple-50 h-screen">
      <div className="bg-purple-50 justify-start items-start inline-flex">
        <SideBar />
        <div className="ml-[15.5vw] h-screen overflow-y-auto flex-1 grow shrink basis-0 self-stretch justify-start items-start inline-flex w-[85vw]">
          <div className="grow shrink basis-0 pt-4 pb-24 justify-start items-center gap-16 flex">
            <div className="grow shrink basis-0 px-8 justify-center items-start flex">
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">
                <div className="self-stretch flex-col justify-start items-start gap-8 flex">
                  <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                    <div className="flex justify-center items-center 3xl:ml-[15vw]">
                      <ProgressSteps ind={ind} setInd={setInd} />
                    </div>

                    <Suspense fallback={<div>Loading...</div>}>
                      <Load setInd={setInd} />
                    </Suspense>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
