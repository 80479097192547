import React, { useEffect, useState } from 'react'
import arrow from "../../assets/chevron-down.svg";
import SideBar from '../Parts/SideBar2';
import { createUserAsync } from '../../redux/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { editUserByOwnId, getUserByOwnId } from '../../api/authAPI';
import { toast } from 'react-toastify';
import DashboardLoader from '../loader/DashboardLoader';

const AddUser = () => {
    const { id } = useParams();
    const nav = useNavigate();
    const dispatch = useDispatch()
    const [localData, setLocalData] = useState({
        name: '',
        email: '',
        password: '',
        role: ''
    });
    const [showDesignationChange, setShowDesignationChange] = useState(false);
    const [showTypeChange, setShowTypeChange] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchUser = async () => {
            setLoading(true)
            try {
                const res = await getUserByOwnId(id);
                setLocalData(res);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
        if (id) fetchUser();
    }, [id])

    const [isLoading, setIsLoading] = useState(false);
    const [localError, setLocalError] = useState('');

    const handleData = (e) => {
        const { name, value } = e.target;
        const keys = name.split('.');

        if (keys.length > 1) {
            // Handling nested properties
            const [key, subKey] = keys;
            setLocalData(prevState => ({
                ...prevState,
                [key]: {
                    ...prevState[key],
                    [subKey]: value
                }
            }));
        } else {
            // Handling flat properties
            setLocalData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleDesignationChange = (designation) => {
        setLocalData({ ...localData, designation });
        setShowDesignationChange(false);
    };

    const handleTypeChange = (role) => {
        setLocalData({ ...localData, role });
        setShowTypeChange(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, email, password, role } = localData;

        // Basic validation
        if (!email.includes('@')) {
            setLocalError('Invalid email address');
            return;
        }
        if (password.length < 6) {
            setLocalError('Password must be at least 6 characters long');
            return;
        }

        setIsLoading(true);
        try {
            if (id) {
                await editUserByOwnId(id, localData);
            }
            else {
                await dispatch(createUserAsync({ role, name, email, password })).unwrap();
                setLocalData({
                    name: '',
                    email: '',
                    password: '',
                    role: ''
                })
                toast.success("User registered :)")
            }
            nav('/user_')
        } catch (error) {
            console.error('Registration field: ', error);
            setLocalError('Registration field');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (localError) {
            const timer = setTimeout(() => {
                setLocalError('');
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [localError]);

    if (loading || isLoading) return <DashboardLoader />

    return (
        <div className="bg-purple-50">
            <div className="justify-start items-start inline-flex">
                <SideBar />
                <div className="grow shrink basis-0 ml-[23vw] lg:ml-[15.5vw] self-stretch bg-purple-50 justify-start items-start flex">
                    <div className="grow shrink basis-0 h-[682px] pt-4 pb-24 justify-start items-center gap-16 flex">
                        <div className="grow shrink basis-0 h-[570px] px-8 justify-center items-start flex">
                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">
                                <div className="self-stretch h-[570px] flex-col justify-start items-start gap-8 flex">
                                    <div className="self-stretch h-[570px] flex-col justify-start items-start gap-6 flex">
                                        <div className="self-stretch justify-start items-start gap-8 inline-flex">
                                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                                                <div className="self-stretch h-[70px] flex-col justify-center items-start gap-1.5 flex">
                                                    <div className="text-zinc-700 text-4xl font-bold font-['Inter'] leading-tight">
                                                        Add User Details
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='flex flex-col w-[80vw]'>
                                            {/* Full Name */}
                                            <div className="self-stretch justify-start items-start gap-6 inline-flex max-w-[70vw]">
                                                <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                                                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                                                        <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                                                            <label
                                                                htmlFor="firstName"
                                                                className="text-zinc-700 text-base font-semibold   leading-tight"
                                                            >
                                                                First Name
                                                            </label>
                                                            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                                                                <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                                                    <input
                                                                        id="firstName"
                                                                        type="text"
                                                                        required
                                                                        placeholder='Alok'
                                                                        className="grow shrink basis-0 text-zinc-700 text-base font-normal   leading-normal focus:outline-none"
                                                                        value={localData.name} onChange={handleData} name="name"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            {/* Email  */}
                                            <div className='flex gap-8 mt-6 flex-1'>
                                                <div className="self-stretch justify-start items-start gap-6 inline-flex">
                                                    <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                                                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                                                            <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                                                                <label
                                                                    htmlFor="emailAddress"
                                                                    className="text-zinc-700 text-base font-semibold   leading-tight"
                                                                >
                                                                    Email Address
                                                                </label>
                                                                <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                                                                    <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                                                        <input
                                                                            id="emailAddress"
                                                                            type="email"
                                                                            required
                                                                            placeholder="name@gmail.com"
                                                                            className="grow shrink basis-0 text-zinc-700 text-base font-normal   leading-normal focus:outline-none"
                                                                            value={localData.email} onChange={handleData} name="email"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {!id && <div className="self-stretch justify-start items-start gap-6 inline-flex">
                                                    <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                                                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                                                            <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                                                                <label
                                                                    htmlFor="password"
                                                                    className="text-zinc-700 text-base font-semibold leading-tight"
                                                                >
                                                                    Password
                                                                </label>
                                                                <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-between items-center gap-2 inline-flex">
                                                                    <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                                                        <input
                                                                            id="password"
                                                                            type={showPassword ? "text" : "password"}
                                                                            required
                                                                            placeholder="password"
                                                                            className="grow shrink basis-0 text-zinc-700 text-base font-normal leading-normal focus:outline-none"
                                                                            value={localData.password} onChange={handleData} name="password"
                                                                        />
                                                                    </div>
                                                                    <button type="button" onClick={() => setShowPassword(!showPassword)} className="ml-2">
                                                                        {showPassword ? (
                                                                            eyeIcon
                                                                        ) : (
                                                                            eyeOffIcon
                                                                        )}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}


                                                <div className="justify-start items-start gap-8 flex">
                                                    <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                                                        <div className="w-[187px] h-[70px] flex-col justify-start items-start gap-1.5 inline-flex">
                                                            <label htmlFor="role" className="text-zinc-700 text-base font-semibold leading-tight">
                                                                Role
                                                            </label>
                                                            <div className="self-stretch bg-white hover:bg-violet-50 rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                                                                <div className="group w-full relative">
                                                                    <button
                                                                        type="button"
                                                                        className="inline-flex px-3.5 py-2.5 text-gray-400 justify-between items-center w-full text-md font-medium focus:outline-none focus:bg-violet-200"
                                                                        onClick={() => setShowTypeChange(!showTypeChange)}
                                                                    >
                                                                        <p>{localData.role || 'User Role'}</p>
                                                                        <img src={arrow} alt="arrow" className="w-5 h-5 relative" />
                                                                    </button>
                                                                    {showTypeChange && (
                                                                        <div className="absolute left-0 z-10 text-start pl-5 w-full mt-1 bg-white divide-y divide-gray-100 rounded-md shadow-lg transition duration-300">
                                                                            <div className="py-1">
                                                                                <p className="m-2 cursor-pointer" onClick={() => handleTypeChange('contentAdmin')}>Content Admin</p>
                                                                                <p className="m-2 cursor-pointer" onClick={() => handleTypeChange('superAdmin')}>Super Admin</p>
                                                                                <p className="m-2 cursor-pointer" onClick={() => handleTypeChange('prof')}>Professor</p>
                                                                                <p className="m-2 cursor-pointer" onClick={() => handleTypeChange('student')}>Student</p>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {localError && <p className="mt-2 text-start text-red-600 text-sm">{localError}</p>}
                                        </div>

                                        <div className=" self-stretch h-12 flex-col justify-start items-start gap-4 flex pt-6">
                                            <button
                                                type="button"
                                                className="self-stretch px-5 py-3 bg-amber-500 rounded-lg shadow border justify-center items-center gap-2 inline-flex"
                                                onClick={handleSubmit}
                                            >
                                                <p className="text-white text-base font-semibold font-['Inter'] leading-normal">

                                                    {isLoading ? 'Submitting...' : 'Submit'}
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const eyeIcon = (
    <svg width="24px" height="24px" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M3 13C6.6 5 17.4 5 21 13" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 17C10.3431 17 9 15.6569 9 14C9 12.3431 10.3431 11 12 11C13.6569 11 15 12.3431 15 14C15 15.6569 13.6569 17 12 17Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
);

const eyeOffIcon = (
    <svg width="24px" height="24px" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M19.5 16L17.0248 12.6038" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 17.5V14" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M4.5 16L6.96895 12.6124" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3 8C6.6 16 17.4 16 21 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
);

export default AddUser