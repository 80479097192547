import React, { useEffect, useState } from 'react';
import SideBar from '../Parts/SideBar2';
import { useSelector } from 'react-redux';
import { selectLoggedInUser } from '../../redux/authSlice';
import { addEntry, deleteEntryById, getAllEntries } from '../../api/configurationAPI';
import { toast } from 'react-toastify';
import Imagekit from '../Parts/Imagekit';
import DashboardLoader from '../loader/DashboardLoader';

const ConfigurationDashboard = () => {


    const mainUser = useSelector(selectLoggedInUser);

    const [accreditations, setAccreditations] = useState([]);
    const [recruiters, setRecruiters] = useState([]);
    const [ranking, setRanking] = useState([]);
    const [states, setStates] = useState([]);
    const [courseNames, setCourseNames] = useState([]);
    const [courseSpecialisations, setCourseSpecialisations] = useState([]);
    const [educationBoards, setEducationBoards] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchEntries = async (field, setState) => {
        setLoading(true);
        try {
            const entries = await getAllEntries(field);
            if (entries.length > 0) {
                setState(entries.map(entry => ({
                    id: entry._id,
                    name: entry.name,
                    logo: entry.logo,
                })));
            }
        } catch (error) {
            console.error(`Failed to fetch entries for ${field}:`, error);
        } finally {
            setLoading(false);
        }
    };
    // dvd 
    useEffect(() => {
        fetchEntries('accreditations', setAccreditations);
        fetchEntries('recruiters', setRecruiters);
        fetchEntries('ranking', setRanking);
        fetchEntries('states', setStates);
        fetchEntries('courseNames', setCourseNames);
        fetchEntries('courseSpecialisations', setCourseSpecialisations);
        fetchEntries('educationBoards', setEducationBoards);
        fetchEntries('languages', setLanguages);
    }, []);

    const handleTagsChange = async (key, name, logoUrl) => {
        try {
            const newTag = await addEntry(key, name, logoUrl);

            switch (key) {
                case 'accreditations':
                    setAccreditations(prev => [...prev, newTag]);
                    break;
                case 'recruiters':
                    setRecruiters(prev => [...prev, newTag]);
                    break;
                case 'ranking':
                    setRanking(prev => [...prev, newTag]);
                    break;
                case 'states':
                    setStates(prev => [...prev, newTag]);
                    break;
                case 'courseNames':
                    setCourseNames(prev => [...prev, newTag]);
                    break;
                case 'courseSpecialisations':
                    setCourseSpecialisations(prev => [...prev, newTag]);
                    break;
                case 'educationBoards':
                    setEducationBoards(prev => [...prev, newTag]);
                    break;
                case 'languages':
                    setLanguages(prev => [...prev, newTag]);
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error(`Failed to add entry for ${key}:`, error);
        }
    };


    const handleRemoveTag = async (key, tagToRemove) => {
        try {
            let tagObject;

            switch (key) {
                case 'accreditations':
                    tagObject = accreditations?.find(tag => tag.name === tagToRemove);
                    setAccreditations(prev => prev.filter(tag => tag._id !== tagObject._id));
                    break;
                case 'recruiters':
                    tagObject = recruiters?.find(tag => tag.name === tagToRemove);
                    setRecruiters(prev => prev.filter(tag => tag.id !== tagObject.id));
                    break;
                case 'ranking':
                    tagObject = ranking?.find(tag => tag.name === tagToRemove);
                    setRanking(prev => prev.filter(tag => tag.id !== tagObject.id));
                    break;
                case 'states':
                    tagObject = states?.find(tag => tag.name === tagToRemove);
                    setStates(prev => prev.filter(tag => tag.id !== tagObject.id));
                    break;
                case 'courseNames':
                    tagObject = courseNames?.find(tag => tag.name === tagToRemove);
                    setCourseNames(prev => prev.filter(tag => tag.id !== tagObject.id));
                    break;
                case 'courseSpecialisations':
                    tagObject = courseSpecialisations?.find(tag => tag.name === tagToRemove);
                    setCourseSpecialisations(prev => prev.filter(tag => tag.id !== tagObject.id));
                    break;
                case 'educationBoards':
                    tagObject = educationBoards?.find(tag => tag.name === tagToRemove);
                    setEducationBoards(prev => prev.filter(tag => tag.id !== tagObject.id));
                    break;
                case 'languages':
                    tagObject = languages?.find(tag => tag.name === tagToRemove);
                    setLanguages(prev => prev.filter(tag => tag.id !== tagObject.id));
                    break;
                default:
                    break;
            }

            if (tagObject) {
                await deleteEntryById(key, tagObject.id); // Delete the tag by ID
            } else {
                console.error(`Tag ${tagToRemove} not found in ${key}`);
            }
        } catch (error) {
            console.error(`Failed to remove tag ${tagToRemove} from ${key}:`, error);
        }
    };

    if (loading) return <DashboardLoader />

    return (
        <div className="flex overflow-hidden">
            <SideBar />
            <div className="ml-[15.5vw] p-4">
                <header>
                    <h1 className="text-2xl font-bold mb-4">Hi, {mainUser.user.name || "Name"}</h1>
                </header>
                <div className="flex flex-col items-center">
                    <div className="flex w-[1200px] border-2 rounded-t-lg px-4 pt-2 justify-between items-center">
                        <div className=" flex flex-col">
                            <h2 className="text-xl font-semibold mb-1">Configuration</h2>
                            <p className="text-gray-600 mb-4">Keep track of your Configurations.</p>
                        </div>
                    </div>

                    <div className="flex w-[1200px] flex-wrap justify-around gap-y-4 pt-4">
                        <TagInputComponent
                            title="Accreditations"
                            description="Add what ranking criteria you want to include"
                            initialTags={accreditations}
                            onTagsChange={(name) => handleTagsChange('accreditations', name)}
                            onTagRemove={(tag) => handleRemoveTag('accreditations', tag)}
                        />
                        <TagInputComponent
                            title="Recruiters"
                            description="Add what states you want to include"
                            initialTags={recruiters}
                            // onTagsChange={(name) => handleTagsChange('recruiters', name)}
                            onTagsChange={(name, logoUrl) => handleTagsChange('recruiters', name, logoUrl)}
                            onTagRemove={(tag) => handleRemoveTag('recruiters', tag)}
                            showImageUpload={true}
                        />
                        <TagInputComponent
                            title="Ranking"
                            description="Add what ranking criteria you want to include"
                            initialTags={ranking}
                            onTagsChange={(name) => handleTagsChange('ranking', name)}
                            onTagRemove={(tag) => handleRemoveTag('ranking', tag)}
                        />
                        <TagInputComponent
                            title="States"
                            description="Add what states you want to include"
                            initialTags={states}
                            onTagsChange={(name) => handleTagsChange('states', name)}
                            onTagRemove={(tag) => handleRemoveTag('states', tag)}
                        />
                        <TagInputComponent
                            title="Course Name"
                            description="Add what ranking criteria you want to include"
                            initialTags={courseNames}
                            onTagsChange={(name) => handleTagsChange('courseNames', name)}
                            onTagRemove={(tag) => handleRemoveTag('courseNames', tag)}
                        />
                        <TagInputComponent
                            title="Course Specialisations"
                            description="Add what states you want to include"
                            initialTags={courseSpecialisations}
                            onTagsChange={(name) => handleTagsChange('courseSpecialisations', name)}
                            onTagRemove={(tag) => handleRemoveTag('courseSpecialisations', tag)}
                        />
                        <TagInputComponent
                            title="Education Boards"
                            description="Add what ranking criteria you want to include"
                            initialTags={educationBoards}
                            onTagsChange={(name) => handleTagsChange('educationBoards', name)}
                            onTagRemove={(tag) => handleRemoveTag('educationBoards', tag)}
                        />
                        <TagInputComponent
                            title="Languages"
                            description="Add what states you want to include"
                            initialTags={languages}
                            onTagsChange={(name) => handleTagsChange('languages', name)}
                            onTagRemove={(tag) => handleRemoveTag('languages', tag)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const TagInputComponent = ({ title, description, initialTags, onTagsChange, onTagRemove, showImageUpload }) => {
    const [tag, setTag] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const [isUploading, setIsUploading] = useState(false);


    const handleAddTag = () => {
        if (tag.trim()) {
            // console.log("logoUrl-->", logoUrl)
            // console.log("showImageUpload-->", showImageUpload)

            if (showImageUpload && !logoUrl) {
                toast.error('Please add the logo.');
                return;
            }
            onTagsChange(tag.trim(), logoUrl);
            setTag('');
            setLogoUrl('');
        } else {
            toast.error('Please enter a value before adding.');
        }
    };

    const handleImageUploadChange = () => {
        setIsUploading(true);
    };


    const handleImageUploadSuccess = (res) => {
        setLogoUrl(res.url);
        setIsUploading(false);
        // console.log("logoUrl-->", logoUrl)
        // console.log("res.url-->", res.url)
    };

    const handleRemoveTag = async (tagToRemove) => {
        try {
            onTagRemove(tagToRemove.name); // Pass the name to the parent function
        } catch (error) {
            console.error(`Failed to remove tag ${tagToRemove.name}:`, error);
            toast.error(`Failed to remove tag ${tagToRemove.name}:`, error);
        }
    };
    useEffect(() => {
        console.log("logoUrl updated:", logoUrl);
    }, [logoUrl]);




    const handleImageUploadError = (err) => {
        console.error('Image upload error:', err);
        setIsUploading(false);
        toast.error('Failed to upload image.');
    };

    return (
        <div className="border border-[#D0D5DD] p-4 rounded-lg mb-4 max-w-[528px] w-full">
            <h3 className="text-lg font-semibold mb-1">{title}</h3>
            <p className="text-gray-500 mb-4">{description}</p>
            <div className='flex gap-3 items-center w-full'>
                <input
                    id={`${title}-input`}
                    type="text"
                    placeholder="Search"
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                    className="flex-1 p-2 border border-[#D0D5DD] rounded-[8px] outline-none"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            handleAddTag();
                        }
                    }}
                />
                <button
                    onClick={handleAddTag}
                    className="bg-[#F79009] border border-[#7F56D9] text-white px-4 py-2 rounded-lg flex-shrink-0"
                >
                    + Add to list
                </button>

            </div>



            {showImageUpload && (
                <div className="mt-[-50px] ml-[240px]">
                    <Imagekit
                        //   id={"logo-upload"} 
                        id={`${title}-logo-upload`}
                        onSuccess={handleImageUploadSuccess}
                        onError={handleImageUploadError}
                        onChange={handleImageUploadChange}
                    />
                    {!logoUrl && (
                        <button
                            onClick={() => document.getElementById(`${title}-logo-upload`).click()}
                            className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-2"
                        >
                            {isUploading ? 'Uploading....' : 'Upload Logo'}
                        </button>
                    )}
                    {logoUrl && (
                        <img src={logoUrl} alt="Logo Preview" className="mt-2 ml-[70px] w-10 h-10 object-contain" />
                    )}
                </div>
            )}



            {initialTags.length > 0 && (
                <div className="flex flex-wrap gap-2 mt-4">
                    {initialTags.map((tag, index) => (
                        <div
                            key={index} // Use id as the key
                            className="flex items-center bg-[#F9F5FF] text-[#344054] font-medium px-3 py-1.5 rounded-full cursor-pointer "
                        >

                            {tag.logo && (
                                <img src={tag.logo} alt={`${tag.name} Logo`} className="w-6 h-6 mr-2 " />
                            )}
                            {tag.name}
                            <button
                                className="ml-2 text-red-600 font-bold"
                                onClick={() => handleRemoveTag(tag)} // Pass the tag object to remove
                            >
                                ×
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};


export default ConfigurationDashboard;
