import React from 'react'
import SideBar from '../Parts/SideBar2'

const DashboardLoader = () => {
    return (
        <div className="flex overflow-hidden">
            <SideBar />
            <div className="fixed top-0 left-[15.5vw] w-[calc(100vw-15.5vw)] h-screen flex items-center justify-center bg-white z-50">
                <span className="loader"></span>
            </div>
        </div>
    )
}

export default DashboardLoader