import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardCard from "./dashboardCard";
import Sidebar2 from "../Parts/SideBar2";
import { getColleges, delCollege, toggleActive } from "../../api/collegeApi";
import { selectLoggedInUser } from "../../redux/authSlice";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { UnsavedChangesContext } from "../../context/UnsavedChangesContext";
import DashboardLoader from "../loader/DashboardLoader";

const CollegeDashboard = () => {
  const [colleges, setColleges] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { hasUnsavedChanges, setHasUnsavedChanges } = useContext(UnsavedChangesContext);
  const mainUser = useSelector(selectLoggedInUser);
  const [loading, setLoading] = useState(false);

  const nav = useNavigate();

  const fetchColleges = async () => {
    try {
      setLoading(true);
      const res = await getColleges();
      setColleges(res);
    } catch (err) {
      toast.error("Error fetching colleges.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchColleges();
  }, []);

  const edit = async (collegeId) => {
    try {
      nav(`/admin/college/${collegeId}`);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteCollege = async (collegeId) => {
    try {
      setLoading(true);
      const res = await delCollege(collegeId);

      if (res === "Deleted.") {
        toast.success("College deleted successfully.");
        fetchColleges();
      } else if (res === undefined) {
        toast.success("College deleted successfully.");
        fetchColleges();
      } else {
        toast.error("Error, try again.");
      }
    } catch (err) {
      toast.error("Error, try again.");
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleActive = async (id, stat) => {
    setLoading(true);
    try {
      const updated = await toggleActive(id, { isActive: !stat })
      setColleges(colleges.map(p =>
        p._id === id ? { ...p, isActive: updated.isActive } : p
      ));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  // Filter colleges based on the search query
  const filteredColleges = colleges?.filter((college) =>
    college?.overview?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) return <DashboardLoader />

  return (
    <div className="flex overflow-hidden">
      <Sidebar2 />
      <div className="ml-[15.5vw] p-4">
        <header>
          <h1 className="text-2xl font-bold mb-4">Hi, {mainUser.user.name || "Name"}</h1>
        </header>
        <div className="flex flex-col items-center">
          <div className="flex w-[1200px] border-2 rounded-t-lg px-4 pt-2 justify-between items-center">
            <div className="flex flex-col">
              <h2 className="text-xl font-semibold mb-1">College Dashboard</h2>
              <p className="text-gray-600 mb-4">Keep track of your colleges.</p>
            </div>

            <button
              className="h-[39px] px-6 py-2 mb-4 bg-orange-500 text-white rounded"
              onClick={() => {
                nav("/admin/college")
                setHasUnsavedChanges(true);
              }
              }
            >
              + Add New College
            </button>
          </div>

          <div className="flex w-[1200px] space-x-[700px] border-2  px-2 py-2 -translate-y-[2px] -translate-x-[14.8px]  ml-[30px] ">
            <div className="flex items-center space-x-2 mb-4">
              <input
                type="text"
                placeholder="Search"
                className="border-2 translate-y-[8px] w-[380px] h-[40px] px-4 py-3 rounded-lg"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button className="translate-y-[8px] h-[40px] px-4 py-2 bg-gray-200 rounded">
                Filters
              </button>
            </div>
          </div>
        </div>

        <div className="w-[1200px] ml-[15.5px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 border-2 rounded-b-lg px-4 py-4 -translate-yx-[38px] -translate-y-[3px]">
          {filteredColleges?.map((college, index) => (
            <DashboardCard
              key={college._id ?? index}
              name={college.overview.name}
              // code={card.code}
              date={college.createdAt.substring(0, 10)}
              isActive={college.isActive}
              toggleActive={() => handleActive(college._id)}
              edit={() => edit(college._id)}
              deletee={() => deleteCollege(college._id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CollegeDashboard;
