import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import BasicInfo from "./basicInfo";
import EduBg from "./EducationBackground";
import Experience from "./experience";
import Specialization from "./specializations";
import { getProfessorById } from "../../api/professorApi";
import { resetProfessor, updateProfessorData } from "../../redux/professorSlice";
import { useDispatch } from "react-redux";
import DashboardLoader from "../loader/DashboardLoader";

const Pages = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [page, setPage] = useState(1);
    const formRefs = useRef({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchProfessorData = async () => {
            if (id) {
                setLoading(true);
                try {
                    const res = await getProfessorById(id);
                    dispatch(updateProfessorData(res.data));
                } catch (error) {
                    console.error("Error fetching professor data:", error);
                } finally {
                    setLoading(false);
                }
            }
            else dispatch(resetProfessor());
        };

        fetchProfessorData();
    }, [id, dispatch]);

    const nextPage = () => {
        if (formRefs.current[page]?.handleSubmit) {
            const isValid = formRefs.current[page].handleSubmit();
            if (isValid) {
                setPage((prevPage) => Math.min(prevPage + 1, 4));
            }
        } else {
            setPage((prevPage) => Math.min(prevPage + 1, 4));
        }
    };

    const previousPage = () => {
        setPage(page => Math.max(1, page - 1));
        if (page === 4) formRefs.current[page]?.handlePrev(); // Call handlePrev on the last page
    };

    const renderPage = () => {
        switch (page) {
            case 1:
                return <BasicInfo ref={(el) => (formRefs.current[1] = el)} />;
            case 2:
                return <EduBg ref={(el) => (formRefs.current[2] = el)} />;
            case 3:
                return <Specialization ref={(el) => (formRefs.current[3] = el)} />;
            case 4:
                return <Experience ref={(el) => (formRefs.current[4] = el)} />;
            default:
                return null; // Handle invalid page numbers
        }
    };

    if (loading) return <DashboardLoader />

    return (
        <div className="flex flex-col bg-purple-50">
            {renderPage()}
            <div className="self-stretch pb-16 -mt-10 px-10 justify-start items-start gap-4 inline-flex ml-[15.7vw]">
                {page > 1 && (
                    <button onClick={previousPage} className="grow shrink basis-0 h-12 px-5 py-3 bg-amber-100 rounded-lg shadow border border-amber-500 justify-center items-center gap-2 flex">
                        <p className="text-amber-500 text-base font-semibold leading-normal">
                            Back
                        </p>
                    </button>
                )}
                {page < 4 ? (
                    <button onClick={nextPage} className="grow shrink basis-0 h-12 px-5 py-3 bg-amber-500 rounded-lg shadow border justify-center items-center gap-2 flex">
                        <p className="text-white text-base font-semibold leading-normal">
                            ({page}/4) Next
                        </p>
                    </button>
                ) : (
                    <button onClick={nextPage} className="grow shrink basis-0 h-12 px-5 py-3 bg-amber-500 rounded-lg shadow border justify-center items-center gap-2 flex">
                        <p className="text-white text-base font-semibold leading-normal">
                            Review
                        </p>
                    </button>
                )}
            </div>
        </div>
    );
};

export default Pages;
